import { PostImageType, WallPost } from 'types/types';

export const isDevelopment = process.env.NODE_ENV === 'development';

let appContainerElement: null | HTMLElement = null;

export const getAppContainerElement = () => {
  if (!appContainerElement) {
    appContainerElement = document.getElementById('vk-feed');
  }

  return appContainerElement;
};

export const generateProxifiedSrc = (src: string) =>
  `https://manutd.one/imageProxy/image?url=${src}`;

export function formatDate(timestamp: number) {
  const dateObject = new Date(timestamp);
  const day = dateObject.getDate();
  const month = translateMonth(dateObject.getMonth(), true);
  const time = dateObject.toLocaleTimeString().split(':').slice(0, 2).join(':');

  return `${day} ${month}, ${time}`;
}

export function translateMonth(
  month: number | string,
  withEndings: boolean = false
) {
  const numberToWord: { [key: number]: string } = {
    0: 'January',
    1: 'February',
    2: 'March',
    3: 'April',
    4: 'May',
    5: 'June',
    6: 'July',
    7: 'August',
    8: 'September',
    9: 'October',
    10: 'November',
    11: 'December',
  };

  const key: string =
    typeof month === 'number' ? (numberToWord[month] as string) : month;

  const endings: { [key: string]: [string, string] } = {
    January: ['ь', 'я'],
    February: ['ь', 'я'],
    March: ['', 'а'],
    April: ['ь', 'я'],
    May: ['й', 'я'],
    June: ['ь', 'я'],
    July: ['ь', 'я'],
    August: ['', 'а'],
    September: ['ь', 'я'],
    October: ['ь', 'я'],
    November: ['ь', 'я'],
    December: ['ь', 'я'],
  };

  const translateDict: { [key: string]: string } = {
    January: 'январ',
    February: 'феврал',
    March: 'март',
    April: 'апрел',
    May: 'ма',
    June: 'июн',
    July: 'июл',
    August: 'август',
    September: 'сентябр',
    October: 'октябр',
    November: 'ноябр',
    December: 'декабр',
  };

  return translateDict[key] + endings[key][Number(withEndings)];
}

export function getPhoto(
  attachments: WallPost['attachments']
): PostImageType | null {
  if (!attachments) {
    return null;
  }

  const photoAttachment = attachments.find(
    (attachment) => attachment.type === 'photo'
  );

  if (
    !photoAttachment ||
    !photoAttachment.photo ||
    !photoAttachment.photo.sizes
  ) {
    return null;
  }

  return photoAttachment.photo.sizes.find((size) => size.type === 'r') || null;
}
export function getVideo(
  attachments: WallPost['attachments']
): PostImageType | null {
  if (!attachments) {
    return null;
  }

  const videoAttachment = attachments.find(
    (attachment) => attachment.type === 'video'
  );

  if (
    !videoAttachment ||
    !videoAttachment.video ||
    !videoAttachment.video.image
  ) {
    return null;
  }

  const video = videoAttachment.video.image.find((image) => image.width > 400);

  if (!video) {
    return null;
  }

  return video;
}

export function getIsImageVertical(image?: PostImageType | null) {
  return image ? image.width * 1.5 < image.height : false;
}

export function getApiEndpoint() {
  const localRest = Boolean(
    document.cookie
      .split(';')
      .find(
        (elem) =>
          elem.trim().split('=')[0] === 'localRest' &&
          elem.split('=')[1] === '1'
      )
  );

  return localRest
    ? 'http://localhost:3210/restApi'
    : 'https://manutd.one/restApi';
}

function loadAdsenseScript() {
  const script = document.createElement('script');
  script.setAttribute('async', 'async');
  script.type = 'text/javascript';
  script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
  document.head.appendChild(script);
}

export function initAdsenseBanner() {
  loadAdsenseScript();

  const inlineScript = document.createElement('script');
  inlineScript.innerHTML = `(adsbygoogle = window.adsbygoogle || []).push({})`;
  document.head.appendChild(inlineScript);
}
