import { getApiEndpoint } from 'helpers/helpers';
import { ThemeContext } from 'hooks/themeContext';
import React, { useContext, useEffect, useRef, useState } from 'react';

import DesktopFeed from 'components/DesktopFeed';
import MobileFeed from 'components/MobileFeed';
import { WallPost } from '../../types/types';

function Feed() {
  const [{ items, isLoading }, setState] = useState({
    items: [],
    isLoading: true,
  });
  const isMobile = useContext(ThemeContext);
  const bannerPosition = useRef(Date.now() % 2);

  useEffect(() => {
    const apiEndpoint = getApiEndpoint();

    fetch(`${apiEndpoint}/getFeed`)
      .then((res) => res.json())
      .then((feed) => {
        setState({ items: feed, isLoading: false });
      });
  }, []);

  return (
    <div className={'FeedWrapper'}>
      {isMobile ? (
        <MobileFeed
          isLoading={isLoading}
          items={items as WallPost[]}
          bannerPosition={bannerPosition.current}
        />
      ) : (
        <DesktopFeed
          isLoading={isLoading}
          items={items as WallPost[]}
          bannerPosition={bannerPosition.current}
        />
      )}
    </div>
  );
}

export default Feed;
