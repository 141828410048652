import classnames from 'classnames';
import { getAppContainerElement } from 'helpers/helpers';
import React, { useCallback, useState } from 'react';

import FeedPost from 'components/FeedPost';
import FeedPostMockup from 'components/FeedPost/FeedPostMockup';
import { MAX_DESKTOP_FEED_HEIGHT } from 'constants/constants';

import styles from './DesktopFeed.module.scss';
import { WallPost } from '../../types/types';

interface DesktopFeedProps {
  isLoading: boolean;
  items: WallPost[];
  bannerPosition?: number;
}

const FIRST_PORTION_LIMIT = 5;

function DesktopFeed(props: DesktopFeedProps) {
  const { isLoading, items, bannerPosition = 1 } = props;
  const [additionalLimit, setLimit] = useState(1);

  const onLoad = useCallback(() => {
    const container = getAppContainerElement();

    if (container) {
      if (container.offsetHeight < MAX_DESKTOP_FEED_HEIGHT) {
        setLimit(additionalLimit + 1);
      }
    }
  }, [additionalLimit]);

  return (
    <div className={classnames('FeedList', styles.desktop)}>
      {isLoading ? (
        <>
          <FeedPostMockup />
          <FeedPostMockup />
        </>
      ) : (
        <>
          {items.slice(0, bannerPosition).map((item) => (
            <FeedPost post={item} key={item.id} />
          ))}
          {items.slice(bannerPosition, FIRST_PORTION_LIMIT).map((item) => (
            <FeedPost post={item} key={item.id} />
          ))}
          {items
            .slice(FIRST_PORTION_LIMIT, FIRST_PORTION_LIMIT + additionalLimit)
            .map((item) => (
              <FeedPost post={item} key={item.id} onLoad={onLoad} />
            ))}
        </>
      )}
    </div>
  );
}

export default React.memo(DesktopFeed);
