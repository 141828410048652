import classnames from 'classnames';
import { formatDate } from 'helpers/helpers';
import { ReactComponent as CommentIcon } from 'icons/comment.svg';
import { ReactComponent as EyeIcon } from 'icons/eye.svg';
import { ReactComponent as LikeIcon } from 'icons/like.svg';
import { ReactComponent as PlayerIcon } from 'icons/player.svg';
import React from 'react';

import styles from './FeedPost.module.scss';

function FeedPostMockup() {
  return (
    <div className={classnames('feedPost', styles.feedPost, styles.mockup)}>
      <div className={classnames(styles.imgWrapper)}>
        <PlayerIcon className={styles.svgIcon} />
        <div className={classnames(styles.label, styles.floatingLabel)}>
          {formatDate(Date.now())}
        </div>
      </div>
      <pre className={styles.content}>
        <div className={styles.mockupLine}></div>
        <div className={styles.mockupLine}></div>
        <div className={styles.mockupLine}></div>
      </pre>
      <div className={styles.footer}>
        <div className={styles.likes}>
          <LikeIcon className={styles.svgIcon} /> 0
        </div>
        <div className={styles.comments}>
          <CommentIcon className={styles.svgIcon} /> 0
        </div>
        <div className={styles.views}>
          <EyeIcon className={styles.svgIcon} /> 0
        </div>
      </div>
    </div>
  );
}

export default FeedPostMockup;
