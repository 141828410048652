import classnames from 'classnames';
import {
  formatDate,
  getIsImageVertical,
  getPhoto as getPhotoCover,
  getVideo as getVideoCover,
} from 'helpers/helpers';
import { ReactComponent as CommentIcon } from 'icons/comment.svg';
import { ReactComponent as EyeIcon } from 'icons/eye.svg';
import { ReactComponent as LikeIcon } from 'icons/like.svg';
import React, { useCallback } from 'react';

import FeedPostImage from 'components/FeedPostImage';

import styles from './FeedPost.module.scss';
import { WallPost } from '../../types/types';

interface FeedPostProps {
  post: WallPost;
  onLoad?: () => void;
}

const getGroupAddress = (category: WallPost['category']) =>
  category === 'epl' ? 'british_balls' : 'manutdone';

const getCategoryName = (category: WallPost['category']) =>
  category === 'epl' ? 'Премьер-лига' : '';

function FeedPost(props: FeedPostProps) {
  const {
    post: {
      date: timestamp,
      attachments,
      text,
      likes,
      views,
      comments,
      owner_id,
      id,
      category,
    },
    onLoad,
  } = props;
  const photo = getPhotoCover(attachments);
  const video = getVideoCover(attachments);
  const image = video || photo;
  const isVertical = getIsImageVertical(image);
  const showDateOnImage = Boolean(image && !isVertical);
  const alt = (text && text.split('.')[0]) || '';

  const date = timestamp ? formatDate(timestamp * 1000) : null;
  const categoryName = getCategoryName(category);

  const onClick = useCallback(() => {
    const url = `https://vk.com/${getGroupAddress(
      category
    )}?w=wall${owner_id}_${id}&utm_source=${getGroupAddress(category)}`;

    window.open(url, '_blank');
  }, [owner_id, id, category]);

  return (
    <div className={classnames('feedPost', styles.feedPost)} onClick={onClick}>
      <FeedPostImage image={image} alt={alt} onLoad={onLoad}>
        {showDateOnImage ? (
          <div
            className={classnames(
              'feedPost__left',
              styles.label,
              styles.floatingLabel
            )}
          >
            {date}
          </div>
        ) : null}
        {showDateOnImage && categoryName.length ? (
          <div
            className={classnames(
              'feedPost__right',
              styles.label,
              styles.floatingLabel,
              styles.right,
              category && styles[category]
            )}
          >
            {categoryName}
          </div>
        ) : null}
      </FeedPostImage>
      <pre className={classnames('feedPost__content', styles.content)}>
        {showDateOnImage ? null : (
          <>
            <div className={classnames(styles.label, styles.category)}>
              {date}
            </div>
            <br />
          </>
        )}
        {text}
      </pre>
      <div className={classnames('feedPost__footer', styles.footer)}>
        <div className={styles.likes}>
          <LikeIcon className={styles.svgIcon} />
          {likes ? likes.count : 0}
        </div>
        <div className={styles.comments}>
          <CommentIcon className={styles.svgIcon} />
          {comments ? comments.count : 0}
        </div>
        <div className={styles.views}>
          <EyeIcon className={styles.svgIcon} />
          {views ? views.count : 0}
        </div>
      </div>
    </div>
  );
}

export default React.memo(FeedPost);
