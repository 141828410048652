import classnames from 'classnames';
import { getIsImageVertical } from 'helpers/helpers';
import { ReactComponent as PlayerIcon } from 'icons/player.svg';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { PostImageType } from 'types/types';

import Image from 'components/Image';

import styles from './FeedPostImage.module.scss';

interface FeedPostImageProps {
  image: PostImageType | null;
  alt?: string;
  children?: ReactNode;
  onLoad?: () => void;
}

function FeedPostImage(props: FeedPostImageProps) {
  const { image, alt, children, onLoad } = props;
  const [isLoaded, setIsLoaded] = useState(false);
  const [url, setUrl] = useState<string | null>(null);

  useEffect(() => {
    if (isLoaded && onLoad) {
      onLoad();
    }
  }, [isLoaded, onLoad]);

  const onImageLoad = useCallback((url: string | null) => {
    setUrl(url);
    setIsLoaded(true);
  }, []);

  if (!image || (isLoaded && !url)) {
    return null;
  }

  const isVertical = isLoaded && getIsImageVertical(image);

  return (
    <div
      className={classnames('feedPost__image-container', styles.imgWrapper, {
        [styles.vertical]: isVertical,
        [styles.mockup]: !isLoaded,
      })}
    >
      {isLoaded ? null : <PlayerIcon className={styles.svgIcon} />}
      <Image
        className={classnames('feedPostImage', styles.postImage, {
          [styles.hidden]: !isLoaded,
        })}
        src={image.url}
        alt={alt}
        onLoad={onImageLoad}
      />

      {children}
    </div>
  );
}

export default FeedPostImage;
