import classnames from 'classnames';
import { ReactComponent as ArrowUpIcon } from 'icons/arrow-up.svg';
import { ReactComponent as MoreIcon } from 'icons/more.svg';
import React, { forwardRef } from 'react';

import styles from './Expander.module.scss';

interface FeedExpanderProps {
  expanded: boolean;
  fixed?: boolean;
  onClick: () => void;
}

function Expander(props: FeedExpanderProps, ref: React.Ref<HTMLDivElement>) {
  const { expanded, fixed } = props;

  return (
    <div
      ref={ref}
      className={classnames(styles.expander, {
        [styles.fixed]: fixed && expanded,
      })}
      onClick={props.onClick}
    >
      {expanded ? (
        <ArrowUpIcon className={styles.icon} />
      ) : (
        <MoreIcon className={styles.icon} />
      )}
    </div>
  );
}

export default forwardRef(Expander);
