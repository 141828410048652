import React, { useCallback, useState } from 'react';

interface ImageProps {
  src: string | null;
  alt?: string;
  className?: string;
  onLoad?: (url: string | null) => void;
}

function Image(props: ImageProps) {
  const { src, className = '', alt, onLoad } = props;
  const [url, setUrl] = useState(src);

  const onImageLoad = useCallback(() => {
    if (onLoad instanceof Function) {
      onLoad(url);
    }
  }, [url, onLoad]);

  const onError = useCallback(() => {
    if (onLoad instanceof Function) {
      onLoad(null);
    }

    setUrl(null);
  }, [onLoad]);

  if (!url) {
    return null;
  }

  return (
    <img
      onError={onError}
      onLoad={onImageLoad}
      className={className}
      src={url}
      alt={alt}
    />
  );
}

export default Image;
