import classnames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import FeedPost from 'components/FeedPost';
import FeedPostMockup from 'components/FeedPost/FeedPostMockup';
import Expander from 'components/MobileFeed/Expander';

import styles from './MobileFeed.module.scss';
import { WallPost } from '../../types/types';

interface MobileFeedProps {
  isLoading: boolean;
  items: WallPost[];
  bannerPosition?: number;
}

/**
 * Margin from the button to the last post in px.
 * !!! Corresponds $expanderTop css variable !!!
 */
const BUTTON_MARGIN = 10;

/**
 * Bottom offset for the floating Expander
 */
const FIXED_BUTTON_MARGIN = BUTTON_MARGIN * 2;

/**
 * Amount of posts in collapsed state
 */
const INITIAL_FEED_LENGTH = 3;

let feedBottom = 0;
let expanderTop = 0;

function MobileFeed(props: MobileFeedProps) {
  const { isLoading, items, bannerPosition = 1 } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const [isFixed, setIsFixed] = useState(false);
  const expanderRef = useRef<HTMLDivElement | null>(null);
  const feedRef = useRef<HTMLDivElement | null>(null);

  const scrollViewportToExpandButton = useCallback(() => {
    if (feedRef.current === null) {
      return;
    }

    const windowHeight = window.innerHeight;
    if (feedRef.current.getBoundingClientRect().bottom < windowHeight) {
      return;
    }

    requestAnimationFrame(() => {
      if (feedRef.current === null) {
        return;
      }

      const scrollOffset =
        windowHeight -
        feedRef.current.getBoundingClientRect().bottom -
        BUTTON_MARGIN;

      window.scrollBy(0, -scrollOffset);
    });
  }, []);

  const updateElementPositions = useCallback(() => {
    if (feedRef.current !== null) {
      feedBottom = Math.ceil(feedRef.current.getBoundingClientRect().bottom);
    }
    if (expanderRef.current !== null) {
      expanderTop = expanderRef.current.getBoundingClientRect().top;
    }
  }, []);

  const scrollHandler = useCallback(() => {
    requestAnimationFrame(() => {
      updateElementPositions();
      if (expanderRef.current === null) {
        return;
      }

      if (isFixed) {
        if (feedBottom + BUTTON_MARGIN <= expanderTop) {
          setIsFixed(false);
        }
      } else if (feedBottom + FIXED_BUTTON_MARGIN > window.innerHeight) {
        setIsFixed(true);
      }
    });
  }, [updateElementPositions, isFixed]);

  const onExpanderClick = useCallback(() => {
    const newState = !isExpanded;
    setIsExpanded(newState);

    if (newState === false) {
      scrollViewportToExpandButton();
      setIsFixed(false);
    } else {
      scrollHandler();
    }
  }, [isExpanded, scrollHandler, scrollViewportToExpandButton]);

  const posts = React.useMemo(
    () => (isExpanded ? items : items.slice(0, INITIAL_FEED_LENGTH)),
    [items, isExpanded]
  );

  useEffect(() => {
    if (isExpanded) {
      document.addEventListener('scroll', scrollHandler);

      return () => {
        document.removeEventListener('scroll', scrollHandler);
      };
    }
  }, [isExpanded, scrollHandler]);

  if (isLoading) {
    return <FeedPostMockup />;
  }

  return (
    <div
      ref={feedRef}
      className={classnames('FeedList', { [styles.fixed]: isFixed })}
    >
      {posts.slice(0, bannerPosition).map((item) => (
        <FeedPost post={item} key={item.id} />
      ))}
      {posts.slice(bannerPosition).map((item) => (
        <FeedPost post={item} key={item.id} />
      ))}
      <Expander
        ref={expanderRef}
        expanded={isExpanded}
        fixed={isFixed}
        onClick={onExpanderClick}
      />
    </div>
  );
}

export default React.memo(MobileFeed);
