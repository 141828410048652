import { getAppContainerElement } from 'helpers/helpers';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  getAppContainerElement()
);
